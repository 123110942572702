import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const AdminRoutes = lazy(() => import('./routes/AdminRoutes'));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Suspense fallback={null}>
      <BrowserRouter basename='/CKYCProcessing'>
        <AdminRoutes />
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  </LocalizationProvider>
);

